//------------------------------------------
// Colors
//------------------------------------------

//primary material design colors
$primaryLight:  material-color('blue', '200');
$primaryMedium: material-color('blue', '500');
$primaryDim:    material-color('blue', '700');
$primaryDark:   material-color('blue', '900');

//secondary (accent) material design colors
$accentLight:  material-color('pink', '100');
$accentMedium: material-color('pink', '200');
$accentDim:    material-color('pink', '400');
$accentDark:   material-color('pink', '700');

//neutral/grey
$neutral50:  material-color('grey', '50');
$neutral100: material-color('grey', '100');
$neutral200: material-color('grey', '200');
$neutral300: material-color('grey', '300');
$neutral400: material-color('grey', '400');
$neutral500: material-color('grey', '500');
$neutral600: material-color('grey', '600');
$neutral700: material-color('grey', '700');
$neutral800: material-color('grey', '800');
$neutral900: material-color('grey', '900');

//text colors -> (white) light on dark
$primaryTextLight:   rgba(255,255,255,1);
$secondaryTextLight: rgba(255,255,255,0.7);
$hintsTextLight:     rgba(255,255,255,0.3);

//text colors -> (black) dark on light
$primaryTextDark:   rgba(0,0,0,0.87);
$secondaryTextDark: rgba(0,0,0,0.54);
$hintsTextDark:     rgba(0,0,0,0.26);

$primaryColor: #00344f;
$primaryThird: #005887;
$primarySecond: #00466B;
$secondaryColor: #FF6600;

$baseGreen: #4CAF50;
$hoveredGreen: #1B5E20;

$btn-default: rgb(43, 187, 173);
$btn-primary: rgb(66, 133, 244);
$btn-danger: rgb(255, 53, 71);
$btn-warning: rgb(255, 136, 0);
$btn-success: rgb(0, 200, 81);
$btn-info: rgb(51, 181, 229);
$btn-plain: rgb(108, 117, 125);

$black87: rgba(0,0,0,0.87);
$black17: rgba(0,0,0,0.17);
$white87: rgba(255,255,255,0.87);
$white17: rgba(255,255,255,0.17);
